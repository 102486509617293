<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
     
      <div class="row" style="margin-top: 50px">
       <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/58.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 STATIC DISSIPATIVE CLEAN ROOM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-220</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/59.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD WHITE NYLON PU PALM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-719</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/60.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD PVC SHEET
                </h4>
                <h5 style="text-align: center">SINT-221</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/61.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  STATIC DISSIPATIVE KNITTED GLOVES

                </h4>
                <h5 style="text-align: center">SINT-223/SINT-223-W</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        
      </div>
      <div class="row" style="margin-top: 30px">
      <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/62.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  GRIPPING AND WIPING CLEAN ROOM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-224</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/63.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  NYLON ESD PU PALM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-225</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/64.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  NYLON ESD PU FINGERTIPS GLOVES

                </h4>
                <h5 style="text-align: center">SINT-226</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/65.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  NYLON INNER GLOVES
                </h4>
                <h5 style="text-align: center">SINT-227</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         
      </div>
       <div class="row" style="margin-top: 30px">
       <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/66.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  HALF FINGER INNER GLOVES

                </h4>
                <h5 style="text-align: center">SINT-228</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/67.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 HALF FINGER ESD INNER GLOVES

                </h4>
                <h5 style="text-align: center">SINT-228E</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/68.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 NYLON GLOVES WITH PU COATING ON FINGERTIPS

                </h4>
                <h5 style="text-align: center">SINT-229</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/69.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  SEALED CLEAN ROOM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-230</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         
      </div>
       <div class="row" style="margin-top: 30px">
       <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/70.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  STATIC DISSIPATIVE ESD GLOVES

                </h4>
                <h5 style="text-align: center">SINT-711</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/71.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD PU PALM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-712</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/72.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD PU FINGERTIPS GLOVES

                </h4>
                <h5 style="text-align: center">SINT-713</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/73.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CONDUCTIVE PU FINGERTIPS GLOVES

                </h4>
                <h5 style="text-align: center">SINT-714</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         
      </div>
       <div class="row" style="margin-top: 30px">
       <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/74.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CARBON PU FINGERTIPS GLOVES
                </h4>
                <h5 style="text-align: center">SINT-715</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/75.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CONDUCTIVE PU PALM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-716</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/76.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CARBON PU PALM GLOVES
                </h4>
                <h5 style="text-align: center">SINT-717</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/77.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD WHITE NYLON PU FINGERTIPS GLOVES

                </h4>
                <h5 style="text-align: center">SINT-718</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         
      </div>
      <div class="row" style="margin-top: 30px">
       <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/78.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  GREY ESD PU PALM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-722</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/gloves">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/79.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  BLACK ESD PU PALM GLOVES

                </h4>
                <h5 style="text-align: center">SINT-723</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
   
       
         
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>